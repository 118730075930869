







import { Component, Vue, Prop } from 'vue-property-decorator';
import { EquipmentSituation } from '@/types/intrador';

@Component
export default class EquipmentSituationIcon extends Vue {
  @Prop(String) protected situation!: EquipmentSituation;

  @Prop({
    type: String,
    default: 'vertical',
    validator(value: any): boolean { return ['horizontal', 'vertical'].indexOf(value) >= 0; },
  }) protected orientation!: string;
}
