




















import {Component, Prop, Vue} from 'vue-property-decorator';

export interface DropdownMenuItem {
  name: string;
  icon?: string;
  permission?: string | string[];
  action: (data: any) => void;
}

@Component
export default class DropdownButton extends Vue {
  @Prop({ type: Array, default: () => [] }) private context!: DropdownMenuItem[];
  @Prop({ type: Object }) private data?: any;

  private isOpen = false;

  get visibleItems() {
    return this.context.filter((item: DropdownMenuItem) => {
      return item.permission === undefined || this.$can(item.permission);
    });
  }

  public open() {
    this.isOpen = true;
  }

}
