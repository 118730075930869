























import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {AssetStatusList, BulkUpdateAssets, BulkUpdateAssetsVariables} from '@/types/auction';
import InputAssetStatus from '@/components/asset/inputs/InputAssetStatus.vue';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';

@Component({
  components: {Spinner, InputAssetStatus},
})
export default class AssetStatus extends Vue {
  @Prop(String) protected assetId!: string;
  @Prop(Object) protected status!: AssetStatusList;
  @Prop({type: Boolean, default: false}) protected editable!: boolean;

  protected loading: boolean = false;
  protected assetStatus: AssetStatusList|null = null;

  @Watch('status', {immediate: true})
  protected restoreStatus() {
    this.assetStatus = this.status;
  }

  protected async changed() {
    if (!this.assetStatus) {
      return;
    }

    this.loading = true;

    try {
      await this.$apollo.mutate<BulkUpdateAssets, BulkUpdateAssetsVariables>({
        client: 'auctionGraphqlClient',
        mutation: require('@/graphql/mutations/assets/BulkUpdateAssets.gql'),
        variables: {
          assets: [{id: this.assetId, status: {connect: this.assetStatus.id}}],
        },
      });
    } catch (e) {
      this.restoreStatus();
    }

    this.loading = false;
  }
}
