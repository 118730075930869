



















































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Audit_audit, PaginateAudits_paginateAudits_data} from '@/types/intrador';
import MetaList from '@/components/MetaList.vue';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';
// @ts-ignore
import DropdownButton, { DropdownMenuItem } from '@/components/elements/dropdown/DropdownButton.vue';

@Component({ components: {DropdownButton, MetaList, Spinner } })
export default class PlanningCard extends Vue {
  @Prop({ type: Object, required: true }) private audit!: PaginateAudits_paginateAudits_data;

  @Prop({ type: Array }) private context?: DropdownMenuItem[];

  @Prop({ type: Object, required: true }) private query!: any;

  @Prop({ type: String, default: null }) private dataKey?: any;

  @Prop({ type: Boolean, default: false }) private open!: boolean;

  @Prop({ type: Object }) private extra!: any;

  private isOpen = false;

  private isLoading: boolean | string = false;

  private data: any | null = null;

  private mounted() {
    this.isOpen = this.open;
  }

  private get auditData() {
    if (this.data) {
      return this.data;
    }

    return this.audit;
  }

  private async load(loading: string) {
    if (!this.data) {
      // We start loading
      this.isLoading = loading;

      // Gather data (this will be instant when it hits cache)
      const {data} = await this.$apollo.query({
        query: this.query,
        variables: {
          id: this.audit.id || this.audit.branch.id,
        },
      });

      // Update local data, disable loading state and open card
      this.data = this.dataKey ? data[this.dataKey] : data;
      this.isLoading = false;
    }
  }

  private async toggle() {
    // When we can close the card, do so
    if (this.isOpen) {
      this.isOpen = false;
      return;
    }

    await this.load('open');

    this.isOpen = true;
  }

  private async dropdown() {
    await this.load('dropdown');

    this.$nextTick(() => {
      (this.$refs.dropdown as DropdownButton).open();
    });
  }
}
