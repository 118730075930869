






import { Component, Prop, Vue } from 'vue-property-decorator';
import { InspectionStatus } from '@/types/intrador';

@Component({})
export default class InspectionStatusLabel extends Vue {
  @Prop(String) protected status!: InspectionStatus;

  get label(): string {
    return this.$inspection.status.getTranslation(this.status);
  }
}
