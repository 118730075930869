import { Component, Prop, Vue } from 'vue-property-decorator';
// @ts-ignore
import { ActionMenuItem } from '@/layouts/back-office/elements/ActionMenu.vue';

export interface ActionItem extends ActionMenuItem {
  id: string;
  permission?: string | string[];
  available: number;
  total: number;
  single: boolean;
  allowEmpty: boolean;
}

@Component
export default class ActionsRegistrarMixin extends Vue {
  @Prop({type: Array, default: () => []}) protected selected!: any[];

  protected hideDisabled: boolean = false;
  private items: ActionItem[] = [];

  public update(action: ActionItem) {
    const index: ActionItem | undefined = this.items.find(((i: ActionItem) => i.id === action.id));

    if (index !== undefined) {
      index.permission = action.permission;
      index.label = action.label;
      index.icon = action.icon;
      index.action = action.action;
      index.available = action.available;
      index.total = action.total;
      index.single = action.single;
      index.allowEmpty = action.allowEmpty;
    }
  }

  public remove(id: string) {
    const index: number = this.items.findIndex(((i: ActionItem) => i.id === id));
    if (index > -1) {
      this.items.splice(index, 1);
    }
  }

  private register(action: ActionItem): ActionsRegistrarMixin {
    const index = this.items.findIndex(((i: ActionItem) => i.id === action.id));

    if (index < 0) {
      this.items.push(action);
    } else {
      this.items[index] = action;
    }

    return this;
  }

  get availableItems(): ActionItem[] {
    return this.items
      .filter((item: ActionItem) => this.$can(item.permission)
        && (!this.hideDisabled || item.available > 0 || item.allowEmpty))
      .map((item: ActionItem) =>  {
        const newItem: ActionItem = Object.assign({}, item);

        if (!newItem.single) {
          newItem.label = item.label + ' (' + item.available + '/' + item.total + ')';
        }
        newItem.disabled = item.available <= 0 && !item.allowEmpty;

        return newItem;
      });
  }
}
