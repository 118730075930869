




































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { snapshot_inspection_parameterValues_images } from '@/types/intrador';
import Card from '@/layouts/back-office/elements/Card.vue';
import {InspectionFormatterImage} from '@/plugins/inspection/InspectionFormatterImageGroup';

@Component({ components: {Card} })
export default class Polaroid extends Vue {
  @Prop(String) protected name!: string;
  @Prop(Boolean) protected banner!: boolean;
  @Prop({type: Boolean, default: false}) protected full!: boolean;
  @Prop(Object) protected image!: snapshot_inspection_parameterValues_images | InspectionFormatterImage;

  private loading = false;

  private get ext() {
    if (this.image.original_url) {
      return (/(?:\.([^.]+))?$/.exec(this.image.original_url) || [])[1];
    }

    return null;
  }

  private get videoType() {
    if (this.image.original_url) {
      return this.ext === 'mp4'
        ? 'video/mp4'
        : 'video/quicktime';
    }

    return null;
  }

  private get isTranscoding() {
    return this.ext !== 'mp4' && this.ext !== 'jpeg' && this.ext !== 'jpg';
  }

  private get hasPiPCheck() {
    if (!this.image.checks) {
      return false;
    }

    return this.image.checks
      .filter((check: any) => check.namespace === 'PictureInPicture')
      .filter((check: any) => check.level === 'ERROR' || check.level === 'WARNING')
      .length > 0;
  }

  private click() {
    this.$emit('click', this.image.id);
  }

  @Watch('image')
  private imageChanged() {
    this.loading = true;
    this.$nextTick(() => {
      this.loading = false;
    });
  }
}
