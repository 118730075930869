



















import {Component, Mixins} from 'vue-property-decorator';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';
import {AssetList, AssetStatusList, BulkUpdateAssetsVariables, UpdateAssetInput} from '@/types/auction';
import ActionModal from '@/layouts/back-office/elements/actions/ActionModal.vue';
import InputAssetStatus from '@/components/asset/inputs/InputAssetStatus.vue';

@Component({
  components: {InputAssetStatus, ActionModal},
})
export default class ChangeAssetStatusAction extends Mixins<ActionMixin<AssetList>>(ActionMixin) {
  public title: string = this.$it('asset.action.change-status.title', 'Change status') as string;
  public icon?: string = 'edit';
  public permission: string[] = ['assets-update', 'assets-status-update'];

  protected assetStatus: AssetStatusList|null = null;

  protected get variables(): BulkUpdateAssetsVariables | null {
    if (!this.assetStatus) {
      return null;
    }

    return {
      assets: this.availableItems.map((asset) => {
        return {id: asset.id, status: {connect: this.assetStatus!.id}};
      }),
    };
  }

  protected onSelect() {
    (this.$refs.actionModal as ActionModal).open();
  }
}
