











import { Component, Prop, Vue } from 'vue-property-decorator';
import { Inspection_inspection } from '@/types/intrador';
import Card from '@/layouts/back-office/elements/Card.vue';

@Component({ components: { Card } })
export default class AssetTimings extends Vue {
  @Prop(Object) protected asset!: any;
}
