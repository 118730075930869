var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('detail',{ref:"query",attrs:{"query":require('@/graphql/queries/assets/Asset.gql'),"variables":_vm.variables,"back-fallback":{ name: 'assets' },"data-key":"asset","client-id":"auctionGraphqlClient"},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var asset = ref.asset;
return [_vm._v(" "+_vm._s(asset.name)+" "),(asset.status)?_c('asset-status',{attrs:{"asset-id":asset.id,"status":asset.status,"editable":false}}):_vm._e()]}},{key:"subtitle",fn:function(ref){
var asset = ref.asset;
return [(asset.assetGroup)?[_c('span',{staticClass:"cursor-default",attrs:{"aria-label":_vm.$it('global.asset-type', 'Asset type'),"data-balloon-pos":"down"}},[_vm._v(" "+_vm._s(asset.assetGroup.name)+" ")]),_vm._v(" • ")]:_vm._e(),_c('span',{directives:[{name:"copy",rawName:"v-copy"}],staticClass:"font-mono",attrs:{"aria-label":_vm.$it('global.serial-number', 'Serial Number'),"data-balloon-pos":"down"}},[_vm._v(" "+_vm._s(asset.serialNumber)+" ")]),(asset.licensePlate)?[_vm._v(" • "),_c('span',{directives:[{name:"copy",rawName:"v-copy"}],staticClass:"font-mono",attrs:{"aria-label":_vm.$it('global.license-plate', 'License Plate'),"data-balloon-pos":"down"}},[_vm._v(" "+_vm._s(asset.licensePlate)+" ")])]:_vm._e()]}},{key:"actions",fn:function(ref){
var asset = ref.asset;
return [_c('action-bar',[_c('action-button',{attrs:{"title":_vm.$it('global.actions', 'Actions'),"direction":_vm.ActionMenuDirection.BottomRight,"multiple":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var register = ref.register;
return [_c('change-asset-status-action',{attrs:{"register":register,"selected":[asset],"single":""}})]}}],null,true)})],1)]}},{key:"default",fn:function(ref){
var asset = ref.asset;
return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md"},[_c('asset-details',{attrs:{"asset":asset}}),_c('asset-timings',{attrs:{"asset":asset}})],1),_c('div',{staticClass:"col-md"},[_c('asset-image',{attrs:{"asset":asset}}),_c('inspection-related',{attrs:{"related-inspections":asset.inspections}})],1)])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }