



























































import {Component, Prop, Vue} from 'vue-property-decorator';
import { Inspection_inspection_relatedInspections, InspectionStatus } from '@/types/intrador';
import Card from '@/layouts/back-office/elements/Card.vue';
import Polaroid from '@/components/Polaroid.vue';
import InspectionStatusLabel from '@/components/inspection/InspectionStatusLabel.vue';
import EquipmentSituationIcon from '@/components/snapshot/EquipmentSituationIcon.vue';

@Component({
  components: {
    Card,
    EquipmentSituationIcon,
    InspectionStatusLabel,
    Polaroid,
  },
})
export default class InspectionRelated extends Vue {
  @Prop(Array) protected relatedInspections!: Inspection_inspection_relatedInspections[];

  private InspectionStatus = InspectionStatus;

  private go(id: string) {
    this.$router.push({ name: 'inspections-detail', params: { id }});
  }

  private goBlank(id: string) {
    const routeData = this.$router.resolve({ name: 'inspections-detail', params: { id }});
    window.open(routeData.href, '_blank');
  }
}
