
























































import { Component, Vue } from 'vue-property-decorator';
import Detail from '@/layouts/back-office/main/Detail.vue';
import AssetDetails from '@/components/asset/detail/AssetDetails.vue';
import AssetTimings from '@/components/asset/detail/AssetTimings.vue';
import InspectionRelated from '@/components/inspection/InspectionRelated.vue';
import AssetStatus from '@/components/asset/AssetStatus.vue';
import Card from '@/components/planning/Card.vue';
import AssetImage from '@/components/asset/detail/AssetImage.vue';
import ActionBar from '@/layouts/back-office/elements/ActionBar.vue';
import ActionButton from '@/layouts/back-office/elements/actions/ActionButton.vue';
import { ActionMenuDirection } from '@/layouts/back-office/elements/ActionMenu.vue';
import ChangeAssetStatusAction from '@/components/asset/actions/ChangeAssetStatusAction.vue';

@Component({
  components: {
    ChangeAssetStatusAction,
    ActionBar,
    ActionButton,
    AssetImage,
    Card,
    AssetStatus,
    InspectionRelated,
    AssetTimings,
    AssetDetails,
    Detail,
  },
})
export default class AssetDetail extends Vue {
  private ActionMenuDirection = ActionMenuDirection;

  private get variables() {
    return {
      id: this.$route.params.id,
    };
  }
}
