



























import {Component, Mixins, Watch} from 'vue-property-decorator';
import InputMixin from '@/layouts/back-office/elements/input/InputMixin';
import {AssetStatusList} from '@/types/auction';
import InputComboSelect from '@/layouts/back-office/elements/input/InputComboSelect.vue';

@Component({
  components: {InputComboSelect},
})
export default class InputAssetStatus extends Mixins<InputMixin<AssetStatusList>>(InputMixin) {
  private get readableValue(): string | null {
    return (this.value)
      ? this.value.name
      : null;
  }
}
